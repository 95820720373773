@import 'app.scss';

.cookies {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: var(--z-index-cookie-banner);
  background-color: var(--color-gray-100);
  padding: var(--spacing-medium) 0;
}

.container {
  @include container(xl);
}

.text {
  position: relative;
  padding-right: 20%;
}

.item {
  margin-bottom: var(--spacing-base);
}

.label {
  position: relative;
  padding-left: var(--spacing-medium);
}

.input {
  position: absolute;
  top: 6px;
  left: 0;
}

.settings {
  position: relative;
  padding-right: 20%;
}

.close {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  svg {
    width: var(--font-size-base);
    height: var(--font-size-base);
  }
}

.save {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
