html {
  font-family: var(--font-family-base);
  color: var(--color-text-base);
}

body {
  font-size: var(--font-size-base);
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--spacing-base);
}

h1 {
  font-size: var(--font-size-h1);
  line-height: 1.05;
  margin-bottom: var(--spacing-base);
}

h2 {
  font-size: var(--font-size-h2);
  line-height: 0.9375;
  margin-bottom: var(--spacing-base);
}

h3 {
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight-medium);
  line-height: 1.04;
}

::marker {
  color: var(--color-brand);
}
