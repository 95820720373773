/*  =========================================================================
    Elements
    Unclassed HTML element selectors
    ========================================================================= */

html,
body,
#__next {
  height: 100%;
}

#__next {
  display: flex;
  flex-direction: column;
  height: 100%;
}

button {
  cursor: pointer;
}

p,
blockquote,
small,
table,
ul,
ol {
  margin-bottom: var(--spacing-medium);
}

img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-bottom: var(--spacing-tiny);
}

label,
legend {
  display: block;
  font-weight: var(--font-weight-bold);

  &[for] {
    cursor: pointer; /* [1] */
  }

  small {
    font-weight: var(--font-weight-normal);
  }
}

a,
.buttonLink {
  color: var(--color-brand);
  text-decoration-skip-ink: auto;
  text-decoration: underline;
  transition: color var(--transition-duration-base)
    var(--transition-timing-base);

  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--color-text-base);
  }

  &:focus {
    @include generic-focus;
  }
}

ol,
ul {
  padding-left: var(--spacing-medium);
}

table {
  th,
  td {
    padding: 0 var(--spacing-small) var(--spacing-small) 0;
  }
}

hr {
  border: none;
  border-bottom: 1px solid var(--color-gray-500);
}

svg {
  display: block;
}

blockquote {
  position: relative;
  padding-left: var(--spacing-base);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background-color: var(--color-brand);
  }
}

small {
  display: inline-block;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

select::-ms-expand {
  display: none;
}
