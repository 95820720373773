/*  =========================================================================
    Variables
    Global variables, config switches.
    You can NOT import this file other .scss files, instead it is imported in _app.js
    ========================================================================= */

:root {
  --container-xl: 1280px;
  --spacing-base: 1rem; // 16px
  --spacing-tiny: calc(var(--spacing-base) * 0.5); // 8px
  --spacing-small: calc(var(--spacing-base) * 0.75); // 12px
  --spacing-medium: calc(var(--spacing-base) * 1.5); // 24px
  --spacing-large: calc(var(--spacing-base) * 3); // 48px
  --spacing-huge: calc(var(--spacing-base) * 4.5); // 72px

  --color-brand: hsl(214, 100%, 36%);
  --color-button-text: hsl(0, 0%, 100%);

  --color-debanensite-primary: hsl(214, 100%, 36%);

  --color-succes-100: hsl(143, 50%, 90%);
  --color-succes-500: hsl(143, 100%, 33%);
  --color-succes-900: hsl(143, 100%, 7%);

  --color-warning-100: hsl(45, 87%, 90%);
  --color-warning-500: hsl(45, 87%, 62%);
  --color-warning-900: hsl(45, 100%, 10%);

  --color-error-100: hsl(0, 100%, 95%);
  --color-error-200: hsl(0, 100%, 77%);
  --color-error-500: hsl(0, 100%, 57%);
  --color-error-700: hsl(0, 100%, 37%);
  --color-error-900: hsl(0, 100%, 10%);

  --color-white: hsl(0, 0%, 100%);
  --color-gray-100: hsl(0, 0%, 92%); // #EBEBEB
  --color-gray-200: hsl(0, 0%, 86%); // #DBDBDB
  --color-gray-300: hsl(0, 0%, 73%); // #BABABA
  --color-gray-500: hsl(240, 1%, 59%); // #959597
  --color-gray-900: hsl(240, 1%, 29%); // #4A4A4C
  --color-gray-900-rgb: 73, 73, 75; // #4A4A4C
  --color-black: hsl(0, 0%, 0%);

  --color-text-base: var(--color-black);

  --font-family-base: 'Open Sans', sans-serif;

  --font-size-base: 1rem; // 16px
  --font-size-small: 0.845rem; // 14px
  --font-size-button: 1.0625rem; // 17px
  --font-size-h1: 2.125rem; // 34px
  --font-size-h2: 1.75rem; // 28px
  --font-size-h3: 1.25rem; // 20px

  --font-weight-normal: 400;
  --font-weight-medium: 600;
  --font-weight-bold: 700;

  --transition-duration-base: 150ms;
  --transition-timing-base: cubic-bezier(
    0.55,
    0,
    0.1,
    1
  ); // Google swift bezier
  --transition-base: all var(--transition-duration-base)
    var(--transition-timing-base);

  --border-base: 1px solid #ccc;

  --border-radius-base: 6px;
  --border-color-base: var(--color-gray-300);

  // z-indexes
  --z-index-cookie-banner: 90;

  // defaults to big touch targets
  --target-base-height: 48px;
  --target-small-height: var(--target-base-height);
  --target-tiny-height: var(--target-base-height);

  // slightly smaller buttons for certain devices
  @media (pointer: fine) {
    --target-base-height: 45px;
    --target-small-height: 40px;
    --target-tiny-height: 1.5rem;
  }

  --content-width: 870px;
}
