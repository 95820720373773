@import 'app.scss';

.footer {
  background-color: var(--color-gray-100);
  padding-top: var(--spacing-medium);
  padding-bottom: var(--spacing-medium);
  margin-top: var(--spacing-huge);
  font-size: var(--font-size-small);
  color: var(--color-debanensite-primary);
  flex-shrink: 0;
}

.container {
  @include container(xl);

  @include from-breakpoint(md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.privacy {
  display: inline-block;
  color: var(--color-debanensite-primary);

  @include until-breakpoint(md) {
    margin-bottom: var(--spacing-small);
  }
}

.poweredBy {
  display: flex;
  align-items: center;

  svg {
    fill: currentColor;
    height: 20px;
    margin-left: var(--spacing-tiny);
    fill: var(--color-debanensite-primary);
  }
}
